.table-title > i {
  font-size: 1.6rem;
}

table {
  table-layout: fixed;
}

.card.disabled {
  opacity: 0.5;
}

.custom-header::after {
  content: none;
}

.accordion-button {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.description {
  display: flex;
  align-content: flex-start;
}
